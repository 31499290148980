import { replace } from 'react-router-dom';
import {
  fetchLatestLeadLoader,
  fetchLoanInfoLoader,
} from '@/common/api/requests/loan/loaderRequests';
import { LoanStatus } from '@/common/types/loan';
import { RouterPath } from '@/router/types';

export const paymentLoader = async () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return replace(RouterPath.Login);
  }

  const latestLead = await fetchLatestLeadLoader(token);
  const leadId = latestLead?.lead_id;

  if (!leadId) {
    return replace(RouterPath.Cabinet);
  }

  const loanInfo = await fetchLoanInfoLoader({ token, leadId });

  if (!loanInfo) {
    return replace(RouterPath.Cabinet);
  }

  const isCommonPayment =
    loanInfo.status === LoanStatus.Operational ||
    loanInfo.status === LoanStatus.OnDeferment;
  const isSchedulePayment = loanInfo?.status === LoanStatus.OnSchedule;
  const isRefinancePayment = loanInfo.status === LoanStatus.RefinancingSigned;

  if (!isCommonPayment && !isSchedulePayment && !isRefinancePayment) {
    return replace(RouterPath.Cabinet);
  }

  return {
    loanInfo,
    isRefinancePayment,
  };
};
