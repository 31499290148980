import { replace } from 'react-router-dom';
import { RouterPath } from '@/router/types';

export const loginLoader = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return replace(RouterPath.Cabinet);
  }
  return null;
};
