import { LoaderFunction, replace } from 'react-router-dom';
import {
  fetchLatestLeadLoader,
  fetchLoanInfoLoader,
} from '@/common/api/requests/loan/loaderRequests';
import { fetchProfileLoader } from '@/common/api/requests/profile/loaderRequsts';
import { LeadStatus } from '@/common/types/lead';
import { LoanStatus } from '@/common/types/loan';
import { BiometryStatus } from '@/entities/Biometry/types';
import { CabinetLoaderData, RouterPath } from '@/router/types';

export const cabinetLoader: LoaderFunction<unknown> = async ({
  request,
}): Promise<CabinetLoaderData | Response> => {
  const splittedUrl = request?.url?.split('/');
  const requestPath = `/${splittedUrl?.[splittedUrl?.length - 1]}`;

  const token = localStorage.getItem('token');

  if (!token) {
    return replace(RouterPath.Login);
  }

  const profile = await fetchProfileLoader(token);
  const latestLead = await fetchLatestLeadLoader(token);

  const isLeadBiometry =
    !profile?.biometry ||
    profile.biometry.status !== BiometryStatus.Success ||
    !profile.informations_requested;

  if (isLeadBiometry) {
    if (requestPath.includes(RouterPath.LeadBiometry)) {
      return {
        profile,
      };
    } else {
      return replace(RouterPath.LeadBiometry);
    }
  }

  const isProfileRefill =
    !!profile &&
    !profile.registration_completed &&
    profile.informations_requested;

  if (isProfileRefill) {
    if (requestPath.includes(RouterPath.ProfileRefill)) {
      return {
        profile,
      };
    } else {
      return replace(RouterPath.ProfileRefill);
    }
  }

  const isLeadApply = !!profile?.registration_completed && latestLead === null;

  if (isLeadApply) {
    if (requestPath.includes(RouterPath.LeadApply)) {
      return {
        profile,
      };
    } else {
      return replace(RouterPath.LeadApply);
    }
  }

  const isBlocking = !!profile?.blocking?.expires_at;
  const isLoanRepeat =
    !isBlocking &&
    (latestLead?.status === LeadStatus.Closed ||
      latestLead?.status === LeadStatus.CustomerRefusal ||
      latestLead?.status === LeadStatus.CustomerDenied);

  if (isLoanRepeat) {
    if (requestPath.includes(RouterPath.Cabinet)) {
      return replace(RouterPath.LoanRepeat);
    }

    return {
      profile,
      latestLead,
    };
  }

  const isLeadSign =
    !!profile?.registration_completed &&
    latestLead?.status === LeadStatus.PendingSMS;

  if (isLeadSign) {
    if (requestPath.includes(RouterPath.LeadSign)) {
      return {
        latestLead,
      };
    } else {
      return replace(RouterPath.LeadSign);
    }
  }

  const isLoanPurpose =
    !!profile?.registration_completed &&
    latestLead?.status === LeadStatus.LoanPurpose;

  if (isLoanPurpose) {
    if (requestPath.includes(RouterPath.LoanPurpose)) {
      return {
        profile,
      };
    } else {
      return replace(RouterPath.LoanPurpose);
    }
  }

  const isPendingStatements =
    !!profile?.registration_completed &&
    latestLead?.status === LeadStatus.PendingStatements;

  if (isPendingStatements) {
    if (requestPath.includes(RouterPath.UploadStatements)) {
      return {
        latestLead,
      };
    } else {
      return replace(RouterPath.UploadStatements);
    }
  }

  const leadId = latestLead?.lead_id;
  const loanInfo = await fetchLoanInfoLoader({ token, leadId });

  const isLoanSubmit =
    !!profile?.registration_completed &&
    (latestLead?.status === LeadStatus.PendingSubmit ||
      latestLead?.status === LeadStatus.CheckingStatements ||
      latestLead?.status === LeadStatus.ConfirmationEntrepreneur ||
      loanInfo?.status === LoanStatus.PendingSubmit);

  if (isLoanSubmit) {
    if (requestPath.includes(RouterPath.LoanSubmit)) {
      return {
        profile,
        latestLead,
        loanInfo,
      };
    } else {
      return replace(RouterPath.LoanSubmit);
    }
  }

  const isLoanBiometry = loanInfo?.status === LoanStatus.PendingBiometry;

  if (isLoanBiometry) {
    if (requestPath.includes(RouterPath.LoanBiometry)) {
      return {
        loanInfo,
      };
    } else {
      return replace(RouterPath.LoanBiometry);
    }
  }

  const isCabinet =
    !isLeadBiometry &&
    !isProfileRefill &&
    !isLeadApply &&
    !isLeadSign &&
    !isLoanPurpose &&
    !isPendingStatements &&
    !isLoanSubmit &&
    !isLoanBiometry;

  if (
    isCabinet &&
    requestPath !== RouterPath.Cabinet &&
    requestPath !== RouterPath.History &&
    requestPath !== RouterPath.Profile
  ) {
    return replace(RouterPath.Cabinet);
  }

  return {
    profile,
    latestLead,
    loanInfo,
  };
};
