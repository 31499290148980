import { replace } from 'react-router-dom';

// TODO: review
export const rejectedLoader = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return replace('/', { status: 301 });
  }
  return null;
};
