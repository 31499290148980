import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import styles from './styles.module.scss';
import { useIsMobile } from '@/common/hooks/useIsMobile';
import { useScrollToTop } from '@/common/hooks/useScrollToTop';
import { ModalError } from '@/components/ModalError';
import { Header } from '@/entities/Header';
import MobileLayout from '@/entities/Layout/Cabinet/mobile';

const DesktopLayout = lazy(() => import('./desktop'));

export const LayoutCabinet = () => {
  useScrollToTop();
  const token = localStorage.getItem('token');
  const isMobile = useIsMobile();
  return (
    <>
      <Header showButton={!token} />
      <main className={styles.main}>
        {isMobile && (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        )}
        {!isMobile && (
          <Suspense>
            <DesktopLayout>
              <Outlet />
            </DesktopLayout>
          </Suspense>
        )}
      </main>
      <ModalError />
    </>
  );
};
