import { Route } from 'react-router-dom';
import { LayoutBaseFooter } from '@/entities/Layout/BaseFooter';
import { HomePage } from '@/pages/Home';
import { loginLoader } from '@/router/loaders/loginLoader';
import { RouterPath } from '@/router/types';

export const commonRouter = (
  <Route element={<LayoutBaseFooter />}>
    <Route path="/" element={<HomePage />} />
    <Route
      path={RouterPath.Login}
      loader={loginLoader}
      lazy={() => import('@/pages/Login')}
    />
    {/* TODO: signup loader ??? */}
    <Route path="/signup" lazy={() => import('@/pages/Signup')} />
    <Route
      path="/payment-common"
      lazy={() => import('@/pages/PaymentCommon')}
    />
    <Route path="/vopros-otvet" lazy={() => import('@/pages/FAQ/ui')} />
    <Route path="/sposoby-oplaty" lazy={() => import('@/pages/PaymentsFAQ')} />
    <Route path="/kontakty" lazy={() => import('@/pages/Contacts')} />
    <Route
      path="/usloviya-predostavleniya-mikrokreditov"
      lazy={() => import('@/pages/LoanConditions')}
    />
    <Route
      path="/kak-poluchit-dengi"
      lazy={() => import('@/pages/MoneySteps')}
    />
    <Route path="/complaint" lazy={() => import('@/pages/Complaint')} />
    <Route path="/mock/:date/:iin" lazy={() => import('@/pages/Backdoor')} />
  </Route>
);
