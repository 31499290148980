import { BiometryProps } from '@/common/types/profile';

export interface BiometryComponentProps {
  title: string;
  onSubmit: () => Promise<void>;
  loading: boolean;
  manualWaiting?: boolean;
  biometry?: BiometryProps;
}

export enum BiometryStatus {
  Waiting = 1,
  Success = 2,
  Unsuccessful = 3,
  Error = 4,
  Expired = 5,
  Blocked = 6,
}
