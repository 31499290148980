export enum LoanStatus {
  PendingSubmit = 1,
  PendingBiometry = 2,
  BankApplication = 3,
  TransferPending = 4,
  TransferCompleted = 5,
  Adjustments = 6,
  Operational = 7,
  Closed = 8,
  Refinanced = 9,
  Sold = 10,
  RefinancingSigned = 11,
  OnSchedule = 12,
  OnDeferment = 13,
}
