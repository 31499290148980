import { Route } from 'react-router-dom';
import { LayoutBaseFooter } from '@/entities/Layout/BaseFooter';
import { cabinetLoader } from '@/router/loaders/cabinetLoader';
import { RouterPath } from '@/router/types';

export const signupRouter = (
  <Route element={<LayoutBaseFooter />}>
    <Route
      path={RouterPath.LeadBiometry}
      loader={cabinetLoader}
      lazy={() => import('@/pages/LeadBiometry')}
    />
    <Route
      path={RouterPath.ProfileRefill}
      loader={cabinetLoader}
      lazy={() => import('@/pages/ProfileRefill')}
    />
    <Route
      path={RouterPath.LeadApply}
      loader={cabinetLoader}
      lazy={() => import('@/pages/LeadApply')}
    />
    <Route
      path={RouterPath.LeadSign}
      loader={cabinetLoader}
      lazy={() => import('@/pages/LeadSign')}
    />
    <Route
      path={RouterPath.UploadStatements}
      loader={cabinetLoader}
      lazy={() => import('@/pages/UploadStatements')}
    />
    <Route
      path={RouterPath.LoanPurpose}
      loader={cabinetLoader}
      lazy={() => import('@/pages/LoanPurpose')}
    />
    <Route
      path={RouterPath.LoanSubmit}
      loader={cabinetLoader}
      lazy={() => import('@/pages/LoanSubmit')}
    />
    <Route
      path={RouterPath.LoanBiometry}
      loader={cabinetLoader}
      lazy={() => import('@/pages/LoanBiometry')}
    />
  </Route>
);
