export enum LeadStatus {
  PendingSMS = 1,
  Processing = 2,
  PendingVerification = 3,
  CustomerRefusal = 4,
  CustomerDenied = 5,
  PendingSubmit = 6,
  MoneyTransfer = 7,
  Converted = 8,
  Adjustments = 9,
  Closed = 10,
  LoanPurpose = 11,
  CheckingStatements = 12,
  ConfirmationEntrepreneur = 13,
  PendingStatements = 14,
  Registration = 999,
}
