import { LeadProps, LoanInfoProps } from '@/common/api/requests/loan/types';
import { ProfileProps } from '@/common/types/profile';

export enum RouterPath {
  Login = '/login',
  Cabinet = '/cabinet',
  Profile = '/profile',
  History = '/history',
  LoanRepeat = '/loan-repeat',
  LeadBiometry = '/lead-biometry',
  ProfileRefill = '/profile-refill',
  LeadApply = '/lead-apply',
  LeadSign = '/lead-sign',
  LoanPurpose = '/loan-purpose',
  UploadStatements = '/upload-statements',
  LoanSubmit = '/loan-submit',
  LoanBiometry = '/loan-biometry',
  Payment = '/payment',
}

export enum NavigateState {
  LoanRepeat = 'LoanRepeat',
}

export interface CabinetLoaderData {
  profile?: ProfileProps;
  latestLead?: LeadProps;
  loanInfo?: LoanInfoProps;
}

export type CabinetLoaderResponse = CabinetLoaderData | void;

export type PaymentLoaderResponse = {
  loanInfo: LoanInfoProps;
  isRefinancePayment: boolean;
};
