import { STATUS } from '@/common/api/constants';
import {
  HandleErrorMessage,
  ResponseHandler,
  ShowOrThrowErrorMessage,
} from '@/common/api/types';
import { clearLocalStore } from '@/common/utils/localStore';
import { RouterPath } from '@/router/types';

export const getErrorMessage = async (response: Response): Promise<string> => {
  try {
    const parsedResponse = await response.json();
    if (
      typeof parsedResponse.error === 'string' ||
      typeof parsedResponse.message === 'string'
    ) {
      return parsedResponse.error || parsedResponse.message;
    } else {
      return 'Неизвестная ошибка';
    }
  } catch {
    return 'Неизвестная ошибка';
  }
};

export const handleErrorMessage: HandleErrorMessage = async ({
  response,
  setErrorMessage,
}) => {
  if (response) {
    const message = await getErrorMessage(response);
    setErrorMessage?.(message);
  }
};

const showOrThrowErrorMessage: ShowOrThrowErrorMessage = async ({
  setErrorMessage,
  response,
}) => {
  const message = await getErrorMessage(response);

  if (setErrorMessage) {
    setErrorMessage(message);
  } else {
    throw new Error(message);
  }
};

export const responseHandler: ResponseHandler = async ({
  response,
  setErrorMessage,
  customHandler,
}) => {
  if (response.status === STATUS.UNAUTHORIZED) {
    clearLocalStore();
    window.location.replace(RouterPath.Login);
    return;
  }
  if (response.status === STATUS.UNPROCESSABLE_ENTITY) {
    await customHandler?.({ response, setErrorMessage });
    return response;
  }
  if (response.status === STATUS.REJECTED) {
    clearLocalStore();
    window.location.replace('/rejected');
    return;
  }
  if (response.status >= STATUS.CLIENT_ERRORS) {
    return showOrThrowErrorMessage({ response, setErrorMessage });
  }
  if (response.status >= STATUS.SERVER_ERRORS) {
    return showOrThrowErrorMessage({ response, setErrorMessage });
  }
  return response;
};
