import { lazy, Suspense, useEffect } from 'react';
import { captureException } from '@sentry/react';
import { useRouteError } from 'react-router';
import { useIsMobile } from '@/common/hooks/useIsMobile';
import { useScrollToTop } from '@/common/hooks/useScrollToTop';
import { Footer } from '@/entities/Footer';
import { Header } from '@/entities/Header';
import { PageSpinner } from '@/shared/PageSpinner';

const ErrorPageMobile = lazy(() => import('./mobile'));
const ErrorPageDesktop = lazy(() => import('./desktop'));

export const ErrorPage = () => {
  useScrollToTop();
  const isMobile = useIsMobile();
  const error = useRouteError() as Error;

  useEffect(() => {
    captureException(error);
  }, [error]);

  const message = error?.message || JSON.stringify(error);

  return (
    <>
      <Header />
      {isMobile && (
        <Suspense fallback={<PageSpinner />}>
          <ErrorPageMobile message={message} />
        </Suspense>
      )}
      {!isMobile && (
        <Suspense fallback={<PageSpinner />}>
          <ErrorPageDesktop message={message} />
        </Suspense>
      )}
      <Footer />
    </>
  );
};
