import { wrapCreateBrowserRouter } from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { LayoutBase } from '@/entities/Layout/Base';
import { LayoutBaseFooter } from '@/entities/Layout/BaseFooter';
import { ErrorPage } from '@/pages/ErrorPage';
import { cabinetRouter } from '@/router/cabinetRouter';
import { commonRouter } from '@/router/commonRouter';
import { indexRedirect } from '@/router/loaders/indexRedirect';
import { paymentLoader } from '@/router/loaders/paymentLoader';
import { rejectedLoader } from '@/router/loaders/rejectedLoader';
import { signupRouter } from '@/router/signupRouter';
import { RouterPath } from '@/router/types';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export default sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="*" loader={indexRedirect} />
      {cabinetRouter}
      {signupRouter}
      {commonRouter}
      <Route element={<LayoutBase />}>
        <Route
          path={RouterPath.Payment}
          loader={paymentLoader}
          lazy={() => import('@/pages/Payment')}
        />
      </Route>
      <Route element={<LayoutBaseFooter />}>
        <Route
          path="/rejected"
          loader={rejectedLoader}
          lazy={() => import('@/pages/Rejected')}
        />
      </Route>
      <Route path="/statements" lazy={() => import('@/pages/Statements')} />
    </Route>
  )
);
