import { HEADERS_JSON } from '@/common/api/constants';
import { responseHandler } from '@/common/api/utils';
import { ProfileProps } from '@/common/types/profile';
import { API_URL } from '@/config';

export const fetchProfileLoader = async (
  token: string
): Promise<ProfileProps> => {
  const response = await fetch(`${API_URL}/cabinet/profile`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      ...HEADERS_JSON,
    },
  });

  const handledResponse = await responseHandler({ response });
  return ((await handledResponse?.json()) as { data: ProfileProps })?.data;
};
