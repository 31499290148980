import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';
import ClockIcon from '@/common/svg/clock-icon.svg';
import HomeIcon from '@/common/svg/home-icon.svg';
import ProfileIcon from '@/common/svg/profile-icon.svg';
import { RouterPath } from '@/router/types';

export const Navigation: FC = () => {
  return (
    <nav className={styles.navBlock}>
      <div className={styles.linkWrapper}>
        <NavLink
          className={({ isActive }) =>
            cn(styles.link, { [styles.active]: isActive })
          }
          to={RouterPath.Cabinet}
        >
          <HomeIcon />
          <FormattedMessage defaultMessage="Главная" />
        </NavLink>
      </div>
      <div className={styles.linkWrapper}>
        <NavLink
          className={({ isActive }) =>
            cn(styles.link, { [styles.active]: isActive })
          }
          to="/history"
        >
          <ClockIcon />
          <FormattedMessage defaultMessage="История" />
        </NavLink>
      </div>
      <div className={styles.linkWrapper}>
        <NavLink
          className={({ isActive }) =>
            cn(styles.link, { [styles.active]: isActive })
          }
          to="/profile"
        >
          <ProfileIcon />
          <FormattedMessage defaultMessage="Анкета" />
        </NavLink>
      </div>
    </nav>
  );
};
