import { FC, ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  Image: string;
  imgAlt: string;
  children: ReactNode;
  height?: number;
  bgAccentFullHeight?: boolean;
  bgAccentColor?: string;
}

export const MobileImageModule: FC<Props> = ({
  Image,
  imgAlt,
  children,
  height = 251,
  bgAccentFullHeight = false,
  bgAccentColor,
}) => (
  <div className={styles.wrapper}>
    <div style={{ height: `${height}px` }} className={styles.flexBlock}>
      <div className={styles.decorBlock}>
        <img src={Image} alt={imgAlt} />
        <div
          style={{
            height: bgAccentFullHeight ? '100%' : undefined,
            backgroundColor: bgAccentColor ?? undefined,
          }}
          className={styles.bgAccent}
        />
      </div>
    </div>
    <div className={styles.childrenBlock}>{children}</div>
  </div>
);
