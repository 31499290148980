import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { tags } from './content';
import styles from './styles.module.scss';
import {
  LOAN_ANNUAL_EFFECTIVE_RATE_PERCENT,
  LOAN_DAY_RATE_MAX_PERCENT,
  LOAN_DAY_RATE_MIN_PERCENT,
  LOAN_DAYS_PERIOD_MAX,
  LOAN_DAYS_PERIOD_MIN,
  LOAN_TRIAL_PERIOD,
  LOAN_TRIAL_PERIOD_RATE,
} from '@/common/constants/loan';
import { Tag } from '@/entities/HomePage/About/Tag';

export const About = () => {
  return (
    <section>
      <div className={cn('container', styles.container)}>
        <h2>
          <FormattedMessage defaultMessage="О нас" />
        </h2>
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <p>
              <FormattedMessage defaultMessage="Вы оказались в ситуации, когда срочно нужны деньги, а занять не у кого? Выплачиваете кредиты и ищете дополнительные средства для погашения долгов? Столкнулись с необходимостью получить финансы прямо сейчас, не откладывая на потом?" />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="Мы предлагаем решение — онлайн микрокредиты от {brand}. Это удобный и надёжный способ быстро решить материальные вопросы, не выходя из дома. Микрокредиты доступны каждому гражданину Казахстана, и процесс их получения занимает всего 10 минут!"
                values={{
                  brand: <span className="font-bold">SelfieCredit</span>,
                }}
              />
            </p>
            <div>
              <h3>
                <FormattedMessage defaultMessage="Почему выбирают нас:" />
              </h3>
              <ul className={styles.list}>
                <li>
                  <span className="font-bold">
                    <FormattedMessage defaultMessage="Моментальное одобрение:" />
                  </span>{' '}
                  <FormattedMessage defaultMessage="онлайн заявка заполняется за считанные минуты, и решение приходит практически мгновенно" />
                </li>
                <li>
                  <span className="font-bold">
                    <FormattedMessage defaultMessage="Простота условий:" />
                  </span>{' '}
                  <FormattedMessage defaultMessage="никаких скрытых комиссий и запутанных требований — всё прозрачно и честно" />
                </li>
                <li>
                  <span className="font-bold">
                    <FormattedMessage defaultMessage="Никаких лишних процентов:" />
                  </span>{' '}
                  <FormattedMessage
                    defaultMessage="первые {period} дней {rate}%"
                    values={{
                      rate: LOAN_TRIAL_PERIOD_RATE,
                      period: LOAN_TRIAL_PERIOD,
                    }}
                  />
                </li>
              </ul>
            </div>
            <div>
              <span className="font-bold">
                <FormattedMessage defaultMessage="Условия кредитования:" />
              </span>
              <ul className={styles.list}>
                <li>
                  <FormattedMessage
                    defaultMessage="Срок микрокредита — от {periodMin} до {periodMax} дней с учётом возможных продлений"
                    values={{
                      periodMin: LOAN_DAYS_PERIOD_MIN,
                      periodMax: LOAN_DAYS_PERIOD_MAX,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage="Ежедневная процентная ставка — до {maxRate}%"
                    values={{
                      maxRate: LOAN_DAY_RATE_MAX_PERCENT,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage="Годовая эффективная ставка вознаграждения — до {effectiveRate}%"
                    values={{
                      effectiveRate: LOAN_ANNUAL_EFFECTIVE_RATE_PERCENT,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Комиссия за досрочное или частичное погашение отсутствует" />
                </li>
              </ul>
            </div>
            <p>
              <span className="font-bold">
                <FormattedMessage defaultMessage="Пример расчета:" />
              </span>{' '}
              <br />
              <FormattedMessage
                defaultMessage="Вы хотите оформить микрокредит в размере 177 000₸ на {period} дней. Ваша ставка составляет до {dayRate}% в день."
                values={{
                  period: LOAN_DAYS_PERIOD_MAX,
                  dayRate: LOAN_DAY_RATE_MAX_PERCENT,
                }}
              />
              <br />
              <FormattedMessage
                defaultMessage="Сумма переплаты за {period} дней составит: 338₸ × {period} дней = 6 760₸"
                values={{
                  period: LOAN_DAYS_PERIOD_MAX,
                }}
              />
              <br />
              <FormattedMessage defaultMessage="Таким образом, по окончании срока вы вернёте:" />
              <br />
              <FormattedMessage defaultMessage="117 000₸ (сумма кредита) + 6 760₸ (проценты) = 123 760₸" />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="Если вы не выплатите микрокредит вовремя, кредитор вправе начислить штраф в размере {rate}% от суммы просроченного платежа за каждый день, но не более 10% от суммы долга"
                values={{ rate: LOAN_DAY_RATE_MIN_PERCENT }}
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="Займ с {brand} — это лёгкость и прозрачность условий, гарантированные каждому клиенту. Оформляйте микрокредит уже сегодня и получите нужную сумму без лишних ожиданий и походов в банк!"
                values={{
                  brand: <span className="font-bold">SelfieCredit</span>,
                }}
              />
            </p>
          </div>
          <div>
            <div className={styles.rightSide}>
              {tags.map((tag, index) => (
                <Tag key={index} text={tag} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
