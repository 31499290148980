import { FC } from 'react';
import styles from './styles.module.scss';
import {
  CONTACT_PHONE,
  CONTACT_PHONE_FORMATTED,
} from '@/common/constants/common';
import PhoneIcon from '@/common/svg/call-calling.svg';

interface Props {
  showSubtitle?: boolean;
}

export const CallCenter: FC<Props> = ({ showSubtitle = true }) => {
  return (
    <div className={styles.contacts}>
      <PhoneIcon />
      <div className={styles.phoneBlock}>
        <a
          id="phone-link"
          className={styles.phoneLink}
          href={`tel:${CONTACT_PHONE}`}
        >
          {CONTACT_PHONE_FORMATTED}
        </a>
        {showSubtitle && <span className={styles.subTitle}>call-center</span>}
      </div>
    </div>
  );
};
