import styles from './styles.module.scss';
import { CONTACT_EMAIL } from '@/common/constants/common';
import EmailIcon from '@/common/svg/email.svg';

export const Email = () => (
  <div className={styles.email}>
    <EmailIcon />
    <a id="email-link" href={`mailto:${CONTACT_EMAIL}`}>
      {CONTACT_EMAIL}
    </a>
  </div>
);
