import { HEADERS_JSON } from '@/common/api/constants';
import {
  LeadProps,
  LoanInfoProps,
  LoanInfoRequest,
} from '@/common/api/requests/loan/types';
import { responseHandler } from '@/common/api/utils';
import { API_URL } from '@/config';

export const fetchLatestLeadLoader = async (token: string) => {
  const response = await fetch(`${API_URL}/cabinet/latest-lead`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      ...HEADERS_JSON,
    },
  });

  const handledResponse = await responseHandler({ response });
  return ((await handledResponse?.json()) as { data: LeadProps })?.data;
};

export const fetchLoanInfoLoader = async ({
  token,
  leadId,
}: Omit<LoanInfoRequest, 'setErrorMessage'>) => {
  const response = await fetch(`${API_URL}/cabinet/loan/${leadId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      ...HEADERS_JSON,
    },
  });

  const handledResponse = await responseHandler({ response });
  return ((await handledResponse?.json()) as { data: LoanInfoProps })?.data;
};
