import { Outlet } from 'react-router';
import styles from './styles.module.scss';
import { useScrollToTop } from '@/common/hooks/useScrollToTop';
import { ModalError } from '@/components/ModalError';
import { Header } from '@/entities/Header';

export const LayoutBase = () => {
  useScrollToTop();
  return (
    <>
      <Header />
      <main className={styles.main}>
        <Outlet />
      </main>
      <ModalError />
    </>
  );
};
