import { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { useNavigationState } from '@/router/hooks/useNavigationState';
import { SkeletonList } from '@/shared/Skeleton';

interface Props {
  children: ReactNode;
  skeletonLength?: number;
}

export const PageLoader: FC<Props> = ({ children, skeletonLength = 5 }) => {
  const { pageLoading } = useNavigationState();
  return (
    <>
      {pageLoading && (
        <div className={styles.wrapper}>
          <SkeletonList length={skeletonLength} />
        </div>
      )}
      {!pageLoading && children}
    </>
  );
};
