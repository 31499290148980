import { Route } from 'react-router-dom';
import { LayoutCabinet } from '@/entities/Layout/Cabinet';
import { cabinetLoader } from '@/router/loaders/cabinetLoader';
import { RouterPath } from '@/router/types';

export const cabinetRouter = (
  <Route element={<LayoutCabinet />}>
    <Route
      path={RouterPath.Cabinet}
      loader={cabinetLoader}
      lazy={() => import('@/pages/Cabinet')}
    />
    <Route
      path={RouterPath.Profile}
      loader={cabinetLoader}
      lazy={() => import('@/pages/Profile')}
    />
    <Route
      path={RouterPath.History}
      loader={cabinetLoader}
      lazy={() => import('@/pages/History')}
    />
    <Route
      path={RouterPath.LoanRepeat}
      loader={cabinetLoader}
      lazy={() => import('@/pages/LoanRepeat')}
    />
  </Route>
);
